import localStorage from 'services/storage/storage';

export const saveTokenToUserSession = (idToken: string) => {
  localStorage.set('idToken', idToken);
};

export const saveSiteIdToUserSession = (siteId: string) => {
  localStorage.set('siteId', siteId);
};

export const getUserSession = (): { idToken?: string; siteId?: string } => {
  const idToken = localStorage.get('idToken') as string | undefined;
  const siteId = localStorage.get('siteId') as string | undefined;

  return { idToken, siteId };
};

export const clearUserSession = () => {
  localStorage.remove('idToken');
  localStorage.remove('siteId');
};

export const getSubdomain = window.location.hostname.split('.')?.[0];
