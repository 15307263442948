import { IconButton, colors } from '@hummingbird/shared';
import styled, { keyframes } from 'styled-components/macro';

import { Logo, LogoLetters } from 'assets/icons';
import { boxShadows } from 'theme';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const CollapsibleSidebar = styled.div`
  position: absolute;
  z-index: 3000;
  display: flex;
  gap: 16px;
  animation: 200ms ease-out ${fadeIn};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: ${colors.navy};
  opacity: 0.5;
`;

export const CollapseSidebarButton = styled(IconButton).attrs({
  style: {
    backgroundColor: colors.white,
    borderColor: colors.pigeon,
  },
})`
  height: fit-content;
  transform: rotate(180deg);

  @media (min-width: 480px) {
    padding: 20px;
    border-radius: 16px;
  }

  @media (max-width: 479px) {
    margin-top: 14px;
  }

  svg {
    position: relative;
    bottom: 4px;
    fill: ${colors.pigeon};
  }
`;

interface SmallScreenHeaderProps {
  $isSidebarOpened: boolean;
}

export const SmallScreenHeader = styled.div<SmallScreenHeaderProps>`
  display: ${({ $isSidebarOpened }) => ($isSidebarOpened ? 'none' : 'flex')};
  gap: 16px;
  align-items: center;
  width: max-content;
  min-width: 420px;
  max-width: 100%;
  min-height: 64px;
  padding: 0 12px;
  overflow: auto;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: ${boxShadows.elevation2};

  @media (min-width: 1200px) {
    display: none;
  }
`;

export const SmallLogo = styled(Logo)`
  padding-left: 4px;

  svg {
    width: 32px;
    height: 32px;
  }

  @media (min-width: 481px) {
    display: none;
  }
`;

export const LargeLogo = styled(LogoLetters)`
  min-width: 120px;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
