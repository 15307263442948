import { colors, fontSizes, zIndexes } from '@hummingbird/shared';
import styled, { css } from 'styled-components/macro';

export const MenuList = styled.div.attrs({ role: 'menu' })`
  bottom: 0;
  z-index: ${zIndexes.dropdownOptions};
  display: flex;
  flex-direction: column;
  width: 200px;
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: 3px 3px 15px ${colors.quartz};
`;

const menuItemStyles = css`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px 24px;
  color: ${colors.navy};
  font-size: ${fontSizes.l};
  text-transform: capitalize;
  cursor: pointer;

  :hover,
  :focus-visible {
    text-decoration: none;
    background-color: ${colors.pearl};
  }

  > svg {
    height: 24px;
    fill: ${colors.mouse};
  }
`;

export const MenuListItem = styled.div`
  ${menuItemStyles}
`;
